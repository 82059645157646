import * as React from "react"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/pagination"
import "swiper/css/navigation"

import Story from "./story"

// import required modules
import { EffectCoverflow, Pagination, Navigation } from "swiper"

export default function Category({ category, initialSlide, setStoryId, openVideoModal }) {
  const { stories } = category

  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        navigation={true}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        initialSlide={initialSlide}
        onSlideChange={(e) => {
          setStoryId(e.activeIndex)
        }}
      >
        {stories.map((story, id) => (
          <SwiperSlide key={id}>
            <Story story={story} openVideoModal={openVideoModal} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
