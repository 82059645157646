import * as React from "react"
import styled from "styled-components"

const StorySection = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`
const StoryMedia = styled.div`
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  video {
    width: 100%;
    height: 100%;
  }
`
// const StoryContent = styled.div`
//   position: absolute;
//   width: 100%;
//   bottom: 10px;
//   padding: 10px 20% 50px 20%;
//   @media screen and (max-width: 767px) {
//     padding: 10px 10% 50px 10%;
//   }
// `
// const StoryTitle = styled.h1`
//   font-style: normal;
//   font-weight: 700;
//   font-size: 36px;
//   line-height: 44px;
//   color: #fafafa;
//   @media screen and (max-width: 767px) {
//     font-weight: 400;
//     font-size: 30px;
//     line-height: 40px;
//   }
// `
// const StoryDescription = styled.p`
//   font-style: normal;
//   font-weight: 700;
//   font-size: 24px;
//   line-height: 29px;
//   color: #ffffff;
//   @media screen and (max-width: 767px) {
//     font-weight: 500;
//     font-size: 20px;
//     line-height: 22px;
//   }
// `
const LinkSection = styled.div`
  position: absolute;
  bottom: 100px;
  transform: translate(-50%);
  left: 50%;
  display: flex;
  @media screen and (max-width: 767px) {
    bottom: 50px;
  }
`
const LinkItem = styled.a`
  min-width: 110px;
  height: 25px;
  color: white;
  display: block;
  font-size: 15px;
  padding: 3px;
  border-radius: 5px;
  margin: 5px;
  text-decoration: none;
  background-color: #ffc72c;
`
export default function Story({ story, openVideoModal }) {
  const { image, /*title,*/ /*description,*/ link, video } = story

  return (
    <StorySection>
      <StoryMedia>
        <img src={image.url} alt="story" />
        {/* <StoryContent>
          <StoryTitle>{title}</StoryTitle>
          <StoryDescription>{description}</StoryDescription>
        </StoryContent> */}
        <LinkSection>
          {video && <LinkItem href="#" onClick={() => openVideoModal(video.url)}>Discover The Film</LinkItem>}
          {link && <LinkItem href={link} target="_blank">Read It Here</LinkItem>}
        </LinkSection>
      </StoryMedia>
    </StorySection>
  )
}
