import * as React from "react"
import styled from "styled-components"

const HomeSection = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
const LogoSection = styled.div`
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // text-align: center;
  // img {
  //   width: 500px;
  //   height: 264px;
  // }
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  // @media screen and (max-width: 767px) {
  //   img {
  //     width: 300px;
  //     height: 158px;
  //   }
  // }
`
// const LogoTitle = styled.h1`
//   font-size: 35px;
//   width: 500px;
//   padding: 10px 50px 10px 50px;
//   @media screen and (max-width: 767px) {
//     font-size: 23px;
//     width: 300px;
//     padding: 10px 25px 10px 25px;
//   }
// `
export default function Home(props) {
  return (
    <HomeSection>
      <LogoSection>
        <img src={props.image.url} alt="logo" />
        {/* <LogoTitle>{props.title}</LogoTitle> */}
      </LogoSection>
    </HomeSection>
  )
}
