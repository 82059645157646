import * as React from "react"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { FaShareAlt } from "react-icons/fa"
import KenBurnsDOM from "kenburns/lib/DOM"
import rectCrop from "rect-crop"
import bezierEasing from "bezier-easing"
import sourcemapicon from "../assets/img/Powered-by-sourcemap.png"

var kenBurns = null
var mapImage = null

const MapSection = styled.div`
  width: calc(
    100% -
      ${(props) => (props.width < props.height ? props.width + "px" : "0px")}
  );
  height: calc(
    100% -
      ${(props) => (props.width > props.height ? props.height + "px" : "0px")}
  );
  img {
    height: 100%;
  }
`
const ShareIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 27px;
  background: #0e2240;
  width: 50px;
  height: 50px;
  padding-top: 10px;
  padding-left: 10px;
  border-radius: 50%;
  cursor: pointer;
`
const SourcemapIcon = styled.div`
  position: absolute;
  right: 20px;
  bottom: ${(props) => (props.width < props.height ? 20 : props.height + 20)}px;
  img{
    width: 100px;
  }
`
export default function Map({ openShareModal, location, width, height }) {
  const { map, zoom, x, y } = location

  const [initialZoom, setInitialZoom] = useState(1)
  const [initialX, setInitialX] = useState(0.5)
  const [initialY, setInitialY] = useState(0.5)

  useEffect(() => {
    var mapSection = document.getElementById("map-section")
    mapImage = document.getElementById("map-image")
    kenBurns = new KenBurnsDOM(mapSection)
  })

  useEffect(() => {
    if (zoom && x && y && kenBurns && mapImage) {
      kenBurns
        .animate(
          mapImage,
          rectCrop(initialZoom, [initialX, initialY]),
          rectCrop(zoom, [x, y]),
          2000,
          bezierEasing(0.6, 0.0, 1.0, 1.0)
        )
        .then(() => {
          kenBurns.animate(
            mapImage,
            rectCrop(zoom, [x, y]),
            rectCrop(zoom, [x, y]),
            2000,
            bezierEasing(0.6, 0.0, 1.0, 1.0)
          )
        })
      setInitialZoom(zoom)
      setInitialX(x)
      setInitialY(y)
    }
  }, [zoom, x, y, setInitialZoom, setInitialX, setInitialY])

  return (
    <>
      <MapSection id="map-section" width={width} height={height}>
        {map && <img id="map-image" src={map.image.url} alt="map" />}
      </MapSection>
      <ShareIcon onClick={openShareModal}>
          <FaShareAlt />
        </ShareIcon>
      <SourcemapIcon width={width} height={height}>
        <img src={sourcemapicon} alt="sourcemap"/>
      </SourcemapIcon>
    </>
  )
}
