import * as React from "react"
import { useMemo, useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import { FullPage } from "react-full-page"
import { theme } from "../theme.css"

import {
  FaTwitter,
  FaFacebook,
  FaGooglePlus,
  FaEnvelope,
  FaPinterest,
  FaLinkedin,
  FaRegWindowClose,
} from "react-icons/fa"

import { ShareButtonRectangle, ShareBlockStandard } from "react-custom-share"

import Modal from "react-modal"

import Layout from "../components/layout"
import Home from "../components/home"
import Category from "../components/category"
import Map from "../components/map"

import styled from "styled-components"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import required modules
import { Pagination, Navigation } from "swiper"

const CloseButton = styled(FaRegWindowClose)`
  float: right;
  cursor: pointer;
`
const ShareTitle = styled.h2`
  font-size: 22px;
`
const ShareContent = styled.h2`
  font-size: 20px;
`
const LinkButton = styled.button`
  background: #072c54;
  color: #ffffff;
  width: 100px;
  height: 30px;
  margin-bottom: 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
`
const CategorySection = styled.div`
  background: #072c54;
  width: 100%;
  height: 100%;
`
const HomeSection = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: ${(props) =>
    props.width < props.height ? props.width + "row" : "column-reverse"};
`
const SlideSection = styled.div`
  width: ${(props) =>
    props.width < props.height ? props.width + "px" : "100%"};
  height: ${(props) =>
    props.width > props.height ? props.height + "px" : "100%"};
  position: relative;
`
const shareStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    width: "400px",
    color: theme.colors.primary,
  },
  overlay: { zIndex: 1 },
}

const videoStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    width: "80%",
    border: "0px",
    background: "transparent",
    color: theme.colors.primary,
  },
  overlay: { zIndex: 1 },
}

export default function Homepage(props) {
  const { image, title, map: main_map, zoom: main_zoom, x: main_x, y: main_y, categories } = props.data.homepage

  const origin = props.location.origin
  const params = new URLSearchParams(props.location.search)
  const story = params.get("story") || 0
  const category = params.get("category") || 0

  const [storyId, setStoryId] = useState(0)
  const [categoryId, setCategoryId] = useState(0)
  const [categoryHistery, setCategoryHistery] = useState({ [category]: story })

  const [showShareModal, setShowShareModel] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [videoURL, setVideoURL] = useState('')

  const openShareModal = () => {
    setShowShareModel(true)
  }

  const closeShareModal = () => {
    setShowShareModel(false)
  }

  const openVideoModal = (url) => {
    setVideoURL(url)
    setShowVideoModal(true)
  }

  const closeVideoModal = () => {
    setShowVideoModal(false)
    setVideoURL('')
  }

  const shareLink = useMemo(() => {
    return `${origin}/?category=${categoryId}&story=${storyId}`
  }, [origin, categoryId, storyId])

  const location = useMemo(() => {
    if (categoryId === 0) return { map: main_map, zoom: main_zoom, x: main_x, y: main_y }
    const { map, zoom, x, y } = categories[categoryId - 1].stories[storyId]
      ? categories[categoryId - 1].stories[storyId]
      : { map: main_map, zoom: main_zoom, x: main_x, y: main_y }
    return {
      map,
      zoom,
      x,
      y,
    }
  }, [categories, categoryId, storyId, main_map, main_zoom, main_x, main_y])

  const [copied, setCopied] = useState(false)

  const copyLink = useCallback(async () => {
    if (shareLink) {
      await navigator.clipboard.writeText(shareLink)
      setCopied(true)
      setTimeout(() => setCopied(false), 400)
    }
  }, [shareLink])

  const shareBlockProps = useMemo(() => {
    return {
      url: shareLink,
      button: ShareButtonRectangle,
      buttons: [
        { network: "Twitter", icon: FaTwitter },
        { network: "Facebook", icon: FaFacebook },
        { network: "GooglePlus", icon: FaGooglePlus },
        { network: "Email", icon: FaEnvelope },
        {
          network: "Pinterest",
          icon: FaPinterest,
          media: shareLink,
        },
        { network: "Linkedin", icon: FaLinkedin },
      ],
      text: `Breitling Watch`,
      longtext: `Breitling Watch`,
    }
  }, [shareLink])

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const updateSize = useCallback(() => {
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    setHeight(windowWidth)
    setWidth(windowHeight)
  }, [])

  useEffect(() => {
    updateSize()
    window.addEventListener("resize", updateSize)
  }, [updateSize])

  return (
    <Layout>
      <FullPage>
        <HomeSection width={width} height={height}>
          <SlideSection width={width} height={height}>
            <Swiper
              direction={"vertical"}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              initialSlide={category}
              onSlideChange={(e) => {
                if (categoryHistery[e.activeIndex]) {
                  setStoryId(categoryHistery[e.activeIndex])
                } else {
                  setStoryId(0)
                }
                setCategoryId(e.activeIndex)
                setCategoryHistery({
                  ...categoryHistery,
                  [categoryId]: storyId,
                })
              }}
            >
              <SwiperSlide>
                <CategorySection id="home">
                  <Home image={image} title={title} />
                </CategorySection>
              </SwiperSlide>
              {categories.map((category, id) => (
                <SwiperSlide key={id}>
                  <CategorySection>
                    <Category
                      category={category}
                      initialSlide={category === id ? story : 0}
                      setStoryId={setStoryId}
                      openVideoModal={openVideoModal}
                    />
                  </CategorySection>
                </SwiperSlide>
              ))}
            </Swiper>
          </SlideSection>
          <Map
            width={width}
            height={height}
            openShareModal={openShareModal}
            location={location}
          ></Map>
        </HomeSection>
      </FullPage>
      <Modal
        isOpen={showShareModal}
        onRequestClose={closeShareModal}
        style={shareStyles}
        contentLabel="Share Modal"
      >
        <CloseButton onClick={closeShareModal} />
        <ShareTitle>Share:</ShareTitle>
        <ShareContent>
          Breitling Super Chronomat Automatic 38 Origins Watch Traceability
          Story Powered by Sourcemap
        </ShareContent>
        <LinkButton onClick={copyLink}>
          {copied ? "Copied" : "Copy Link"}
        </LinkButton>
        <ShareBlockStandard {...shareBlockProps} />
      </Modal>
      <Modal
        isOpen={showVideoModal}
        onRequestClose={closeVideoModal}
        style={videoStyles}
        contentLabel="Video Modal"
      >
        <video style={{ width: "100%" }} autoPlay loop preload={"auto"} controls>
          <source src={videoURL} type="video/mp4" />
        </video>
      </Modal>
    </Layout>
  )
}

export const query = graphql`
  {
    homepage {
      id
      title
      image {
        id
        url
      }
      map {
        id
        title
        image {
          id
          url
        }
      }
      zoom
      x
      y
      categories {
        id
        title
        stories {
          id
          title
          description
          image {
            id
            url
          }
          video {
            id
            url
          }
          link
          map {
            id
            title
            image {
              id
              url
            }
          }
          zoom
          x
          y
      }
      }
    }
  }
`
